export const getCategories = (categories) => {
    let cat_name = ''
    if (categories)
        categories.map(val => {
            cat_name += val.en_name.replace('&amp;', '&') ?? ''
            cat_name += '/'
        })
    return cat_name
}
export const gender = (val) => {
    switch (parseInt(val)) {
        case 1:
            return 'male'
        case 2:
            return 'female'
        case 3:
            return 'Prefer not to say'
    }
}