import {TRACKERS} from "@/constants/events";
import {
    gtmCommonObjects,
    gtmProductDetails,
    gtmCategoriesHamburgerMenu,
    gtmPageName,
    gtmCartArrayPipeSeperated,
    gtmReviewSortLabel,
    gtmPaymentType
} from "@/helper/gtm";
import {useCartStore} from '@/store/cart'

export default {
    [TRACKERS.LOGIN](user) {
        const gtm = useGtm()

        gtm && gtm?.push({
            event: 'tvc_user_login',
            user_id: user?.customer_id,
            user_phone: `+${user?.telephone}`,
            user_email: user?.email
        })
    },
    [TRACKERS.LOGO_CLICK]() {
        const gtm = useGtm()
        gtm.push({
            event: 'logo_click',
            eventCategory: 'navigation_menu',
            eventAction: 'logo_click',
            init_point: 'Home',
            ...gtmCommonObjects()
        })
    },
    [TRACKERS.SEARCH_INITATE]() {
        const gtm = useGtm()
        gtm.push({
            event: 'search_inititate',
            eventCategory: 'search_bar',
            eventAction: 'search_initiate',
            init_point: 'Home',
            ...gtmCommonObjects()
        })
    },
    [TRACKERS.SEARCH_TERM]({eventLabel, eventAction}) {
        const gtm = useGtm()
        gtm.push({
            event: 'search_term',
            eventCategory: 'search_bar',
            eventAction,
            eventLabel,
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.SEARCH_PRODUCT](product) {
        const gtm = useGtm()
        gtm.push({
            event: 'search_product',
            eventCategory: 'search_bar',
            eventAction: 'search_product_click',
            eventLabel: product.en_name,
            init_point: 'Home',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.HAM_BURGER_MENU]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'hamburger_menu',
            eventCategory: 'hamburger_menu',
            eventAction,
            eventLabel: gtmCategoriesHamburgerMenu(eventLabel),
            init_point: 'Home',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.NAVIGATION_MENU]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'navigation_menu',
            eventCategory: 'navigation_menu',
            eventAction,
            eventLabel,
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.PRODUCT_CLICKED]({product}) {
        const gtm = useGtm()
        const eventLabel = product.en_name
        const eventAction = ''
        gtm.push({
            event: 'product_click',
            eventCategory: 'product_click',
            eventAction,
            eventLabel,
            init_point: 'Home',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CTA_CLICK]({eventAction, eventLabel, product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'cta_click',
            eventCategory: 'cta_click',
            eventAction,
            eventLabel,
            init_point: 'Home',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    // [TRACKERS.SCROLL_TRACKING](scrollPercentage) {
    //     const gtm = useGtm()
    //     gtm.push({
    //         event: 'scroll_tracking',
    //         eventCategory: 'scroll_tracking',
    //         eventAction: `${scrollPercentage}%`, // 8%, 20%, 35% etc',
    //         eventLabel: gtmPageName(route.name), // home, login, cart etc',
    //         init_point: 'Home',
    //         product_details: {},
    //         ...gtmCommonObjects(this)
    //     })
    // },
    [TRACKERS.CAROUSAL_CLICK]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'carousal_click',
            eventCategory: 'user_interaction',
            eventAction,
            eventLabel,
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.TOP_PRODUCT_ARROW]() {
        const gtm = useGtm()
        gtm.push({
            event: 'top_product_arrow',
            eventCategory: 'user_interaction',
            eventAction: 'top_product_arrow',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.HELP_SECTION]({eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'help_section',
            eventCategory: 'user_interaction',
            eventAction: 'help_section',
            eventLabel,
            init_point: 'Brand',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.BOTTOM_NAVIGATION]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'bottom_navigation',
            eventCategory: 'bottom_navigation',
            eventAction,
            eventLabel,
            init_point: 'Brand',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.BRAND_SELECT]({brand, letter}) {
        const gtm = useGtm()
        gtm.push({
            event: 'brand_select',
            eventCategory: 'brand_select',
            eventAction: letter,
            eventLabel: brand.name,
            init_point: 'Brand',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.LOGIN_INITATE]() {
        const gtm = useGtm()
        gtm.push({
            event: 'login_initate',
            eventCategory: 'account_interactions',
            eventAction: 'login_click',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.REGISTER_INITIATE]() {
        const gtm = useGtm()
        gtm.push({
            event: 'register_initiate',
            eventCategory: 'account_interactions',
            eventAction: 'register_click',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.LOGIN_SUCCESS](eventAction) {
        const gtm = useGtm()
        gtm.push({
            event: 'login_success',
            eventCategory: 'account_interactions',
            eventAction,
            eventLabel: 'login_success',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.LOGIN_FAIL](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'login_fail',
            eventCategory: 'account_interactions',
            eventAction: 'login_fail',
            eventLabel,
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.FORGOT_PASSWORD]() {
        const gtm = useGtm()
        gtm.push({
            event: 'forgot_password',
            eventCategory: 'account_interactions',
            eventAction: 'reset_password',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.REGISTRRATION_SUCCESS]() {
        const gtm = useGtm()
        gtm.push({
            event: 'registeration_success',
            eventCategory: 'account_interactions',
            eventAction: 'registeration_success',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.REGISTRATION_FAIL](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'registration_fail',
            eventCategory: 'account_interactions',
            eventAction: 'registeration_fail',
            eventLabel, // Telephone number is wrong! etc',
            init_point: 'Home',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.MY_ACCOUNT]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'my_account',
            eventCategory: 'my_account',
            eventAction,
            eventLabel,
            init_point: 'My account',
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.PLP_INTERACTION]({eventAction, eventLabel, product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'plp_interaction',
            eventCategory: 'plp_interaction',
            eventAction,
            eventLabel, // eg: Beauteous Advanced Formulation Multi purposes Solution - 100ml',
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.FILTER_INTERACTIONS]({eventAction, filters}) {
        const gtm = useGtm()
        gtm.push({
            event: 'filter_interactions',
            eventCategory: 'filter_interactions',
            eventAction, // eg: lenses_brands, lenses_color',
            eventLabel: gtmCartArrayPipeSeperated(filters), // (pipe_seperated values) eg: Adee | Lens Me etc',
            init_point: 'Product List Page',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.PRODUCT_SORTING](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'product_sorting',
            eventCategory: 'plp_interaction',
            eventAction: 'product_sorting',
            eventLabel,
            init_point: 'Product List Page',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.MORE_PRODUCTS]({eventAction, eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'more_products',
            eventCategory: 'plp_interaction',
            eventAction, // Lenses, Makeup etc',
            eventLabel, // eg: Page 2, Page 3 , Next etc',
            init_point: 'Product List Page',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.FAVORITE_ADD]({product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'favorite_add',
            eventCategory: 'product_interaction',
            eventAction: 'favorite_add',
            eventLabel: product.name,
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.FAVORITE_REMOVE]({product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'favorite_remove',
            eventCategory: 'product_interaction',
            eventAction: 'favorite_remove',
            eventLabel: product.name,
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.PDP_MORE_PRODUCT_DETAILS]({eventAction, product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'pdp_more_product_details',
            eventCategory: 'product_interaction',
            eventAction,
            eventLabel: product.name,
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CHECKOUT_3_PAYMENT](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_3_payment',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_3_payment',
            eventLabel: gtmPaymentType(eventLabel),
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CHECKOUT_OFFERS]({eventLabel}) {
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_offers',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_offers',
            eventLabel,
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.ORDER_SUCCESS](data) {
        const gtm = useGtm()
        gtm.push({
            event: 'ecomm_purchase',
            eventCategory: 'shopping_checkout',
            eventAction: 'order_confirm',
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(data)
        })
    },
    [TRACKERS.PRODUCT_RATING]({eventAction, product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'product_rating',
            eventCategory: 'product_rating',
            eventAction,
            eventLabel: product.name, // '{{product_name}} // eg: Anesthesia Coloured Lenses - Addict Marron',
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.REVIEW_SORT]({eventLabel, product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'review_sort',
            eventCategory: 'product_rating',
            eventAction: 'review_sort',
            eventLabel: gtmReviewSortLabel(eventLabel),
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.REVIEW_LIKE_COMMENT]({type, product}) {
        const gtm = useGtm()
        gtm.push({
            event: `review_${type}`,
            eventCategory: 'product_rating',
            eventAction: `review_${type}`,
            eventLabel: product.name,
            init_point: 'Product List Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.RECOMMENDED_PRODUCT]({product}) {
        const gtm = useGtm()
        gtm.push({
            event: 'recommended_product',
            eventCategory: 'product_interaction',
            eventAction: 'recommended_product',
            eventLabel: `recommended_prod_${product.en_name}`,
            init_point: 'Product Detail Page',
            product_details: gtmProductDetails(product),
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CHECKOUT_1_SHOPPING_BAG]() {
        const cartStore = useCartStore()
        const {cartProducts} = storeToRefs(cartStore)
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_1_shopping_bag',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_1_shopping_bag',
            eventLabel: gtmCartArrayPipeSeperated(cartProducts.value), // '{{cart_products}} // pipe seperated',
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.BEGIN_CHECKOUT](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_2_address_confirm',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_2_address_confirm',
            eventLabel, // Add delivery address, Continue, Verify Phone number, edit, etc',
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CHECKOUT_2B_NEW_ADDRESS](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_2b_new_address',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_2b_new_address',
            eventLabel,
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
    [TRACKERS.CHECKOUT_2A_SET_LOCATION](eventLabel) {
        const gtm = useGtm()
        gtm.push({
            event: 'checkout_2a_set_location',
            eventCategory: 'shopping_checkout',
            eventAction: 'checkout_2a_set_location',
            eventLabel, // '{{city}} '
            init_point: 'Checkout',
            product_details: {},
            ...gtmCommonObjects(this)
        })
    },
}