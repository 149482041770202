import {TRACKERS} from "@/constants/events";
import {
    ecommerceBannerGA3,
    ecommerceBannerGA4,
    ecommerceCompleteOrderGA3,
    ecommerceCompleteOrderGA4,
    ecommerceItemGA3,
    ecommerceItemGA4,
    ecommerceMultipleItemsGA3,
    ecommerceMultipleItemsGA4,
    gtmPageName
} from "@/helper/gtm";
import {useCurrencyStore} from "@/store/currency";
import {useCartStore} from "@/store/cart";
import {storeToRefs} from "pinia";

export default {
    [TRACKERS.BANNER_CLICK](banner) {
        const gtm = useGtm()

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [ecommerceBannerGA3(banner)]
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'select_promotion',
            ecommerce: {
                ...ecommerceBannerGA4(banner),
                items: []
            }
        })
    },
    [TRACKERS.PRODUCT_DETAILS_VIEWED](product) {
        const gtm = useGtm()
        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'productdetail',
            ecommerce: {
                detail: {
                    actionField: {list: gtmPageName()}, // 'detail' actions have an optional list property.
                    products: [ecommerceItemGA3(product)]
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'view_item',
            ecommerce: {
                items: [ecommerceItemGA4(product)]
            }
        })
    },
    [TRACKERS.PRODUCT_CLICKED]({product_id = '', en_name = '', price = '', index = 0} = null) {
        const product = {
            id: product_id,
            name: en_name,
            price_formated: price
        }

        const gtm = useGtm()

        // Get currency
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'productClick',
            ecommerce: {
                currencyCode: currency.value,
                click: {
                    actionField: {list: gtmPageName()}, // Optional list property.
                    products: [ecommerceItemGA3(product, {index})]
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'select_item',
            ecommerce: {
                items: [ecommerceItemGA4(product, {index})]
            }
        })
    },
    [TRACKERS.INCREMENT_CART]({product = null, option = null, index = 0}) {

        const gtm = useGtm()

        // Get currency
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: currency.value,
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        ecommerceItemGA3(product, {
                            selectedOption: option,
                            index
                        })
                    ]
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'add_to_cart',
            ecommerce: {
                items: [
                    ecommerceItemGA4(product, {
                        selectedOption: option,
                        index
                    })
                ]
            }
        })
    },
    [TRACKERS.ADD_TO_CART]({product = null, option = null, index = 0}) {

        const gtm = useGtm()

        // Get currency
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: currency.value,
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        ecommerceItemGA3(product, {
                            selectedOption: option,
                            index
                        })
                    ]
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'add_to_cart',
            ecommerce: {
                items: [
                    ecommerceItemGA4(product, {
                        selectedOption: option,
                        index
                    })
                ]
            }
        })
    },
    [TRACKERS.REMOVE_FROM_CART]({product = null, index = 0}) {

        const gtm = useGtm()

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'removeFromCart',
            ecommerce: {
                items: [
                    ecommerceItemGA3(product, {index, passedQuantity: true})
                ]
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'remove_from_cart',
            ecommerce: {
                items: [
                    ecommerceItemGA4(product, {index, passedQuantity: true})
                ]
            }
        })
    },
    [TRACKERS.BEGIN_CHECKOUT](param = '') {

        const gtm = useGtm()

        // Get currency
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // Cart store
        const cartStore = useCartStore()
        const {cart, cartProducts} = storeToRefs(cartStore)

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'checkout',
            ecommerce: {
                currencyCode: currency.value,
                checkout: {
                    actionField: {step: 1, option: 'Address section'}, // Checkout Step number: 1,2,3 ...etc with option.
                    value: cart.value?.total_raw,
                    products: ecommerceMultipleItemsGA3(
                        cartProducts.value,
                        {
                            passedQuantity: true
                        }
                    )
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'begin_checkout',
            ecommerce: {
                step: '1',
                option: 'Address section',
                value: cart.value?.total_raw,
                items: ecommerceMultipleItemsGA4(
                    cartProducts.value,
                    {
                        passedQuantity: true
                    }
                )
            }
        })
    },
    [TRACKERS.Add_SHIPPING_INFO]() {

        const gtm = useGtm()

        // Get currency
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // Cart store
        const cartStore = useCartStore()
        const {cart, cartProducts} = storeToRefs(cartStore)

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'checkout',
            ecommerce: {
                currencyCode: currency.value,
                checkout: {
                    actionField: {step: 2, option: 'Continue add Shipping'}, // Checkout Step number: 1,2,3 ...etc with option.
                    products: ecommerceMultipleItemsGA3(
                        cartProducts.value,
                        {
                            passedQuantity: true
                        }
                    )
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'add_shipping_info',
            ecommerce: {
                currency: 'SAR',
                value: cart.value?.total_raw,
                coupon: '',
                shipping_tier: '',
                items: ecommerceMultipleItemsGA4(cartProducts.value, {
                    passedQuantity: true
                })
            }
        })
    },
    [TRACKERS.ORDER_SUCCESS](data) {

        const gtm = useGtm()

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'checkout-complete',
            ecommerce: {
                currencyCode: 'SAR',
                purchase: {
                    actionField: ecommerceCompleteOrderGA3(data),
                    products: ecommerceMultipleItemsGA3(data.products, {
                        passedQuantity: true
                    })
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'purchase',
            ecommerce: {
                ...ecommerceCompleteOrderGA4(data),
                items: ecommerceMultipleItemsGA4(data.products, {
                    passedQuantity: true
                })
            }
        })

    },
    [TRACKERS.ORDER_CANCELLED](orderId) {

        const gtm = useGtm()

        // GA3 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'refund',
            ecommerce: {
                refund: {
                    actionField: {id: orderId} // Transaction ID. Required for purchases and refunds.
                }
            }
        })

        // GA4 Snippet
        gtm.push({ecommerce: null}) // Clear the previous ecommerce object.
        gtm.push({
            event: 'order-canceled',
            ecommerce: {
                transaction_id: orderId // Transaction ID. Required for purchases and refunds.
            }
        })

    }
}