import {useAuthStore} from "@/store/auth";
import {getEnCountryName} from "~/helper/index";
import {PAYMENT_TYPES, TAMARA_PAYMENT_TYPES} from "@/constants/payment";

export const ecommerceItemGA4 = (
    product,
    {selectedOption = null, index = 0, passedQuantity = false} = 0
) => {
    const product_details = {
        item_name: '', // Name or ID is required.
        item_id: '',
        price: '',
        item_brand: '',
        item_category: '',
        item_variant: '',
        item_list_name: '',
        item_list_id: '',
        index: index + 1,
        quantity: 1,
        product_discount: '', // Product_discount (in percentage)
        product_price: '', // Product_price. The price of the product.Discount offered for a particular product.
        product_discounted_price: '', // Product_discounted_price. The price of the product after applying the discount.
        product_tags: 'General', // product_tags.Tag attached to the product i.g Exclusive, New etc.
        product_department: '' // product_department.Department the product belongs to i.g Makeup, Perfume, Care etc.
    }

    if (!product) return product_details

    if (
        product.category_hierarchy !== undefined &&
        product.category_hierarchy.length
    ) {
        product.category_hierarchy.map((val, index) => {
            product_details[`item_category${index ? index + 1 : '' || ''}`] =
                val.en_name
        })
        product_details.product_department = product.category_hierarchy[0].en_name
    }

    if (selectedOption?.en_name || selectedOption?.name) {
        product_details.variant = selectedOption.en_name
            ? selectedOption.en_name
            : selectedOption.name
    }

    if (product.option !== undefined && product.option.length) {
        product_details.item_variant = product.option[0].value
    }

    if (product.special !== undefined && product.special.length) {
        product_details.product_discount = product.special[0].discount_ratio.replace(
            '-',
            ''
        )
        product_details.product_discounted_price = product.special[0].price_formated.replace(
            /[^0-9.-]+/g,
            ''
        )
    }

    if (product.special !== undefined && product.is_exclusive) {
        product_details.product_tags = 'Exclusive'
    }

    if (product.special !== undefined && product.is_new) {
        product_details.product_tags = 'New'
    }

    if (product.manufacturer !== undefined && product.manufacturer) {
        product_details.item_brand = product.manufacturer
    }

    if (product.manufacturer_name !== undefined && product.manufacturer_name) {
        product_details.item_brand = product.manufacturer_name
    }

    if (passedQuantity && product.quantity !== undefined && product.quantity) {
        product_details.quantity = product.quantity
    }

    if (product.id !== undefined && product.id) {
        product_details.item_id = product.id
    }

    if (product.product_id !== undefined && product.product_id) {
        product_details.item_id = product.product_id
    }

    product_details.item_name =
        product.en_name !== undefined ? product.en_name : product.name
    product_details.price =
        product.price_formated !== undefined
            ? product.price_formated.replace(/[^0-9.-]+/g, '')
            : Number(product.price).toFixed(2)
    product_details.product_price =
        product.price_formated !== undefined
            ? product.price_formated.replace(/[^0-9.-]+/g, '')
            : Number(product.price).toFixed(2)

    return product_details
}

export const ecommerceMultipleItemsGA4 = (
    products,
    {passedQuantity = false} = 0
) => {
    let product_details = {}

    if (products && !products.length) return product_details
    if (products)
        return products.map((product, index) => {
            product_details = {
                item_name: '', // Name or ID is required.
                item_id: '',
                price: '',
                item_brand: '',
                item_category: '',
                item_variant: '',
                item_list_name: '',
                item_list_id: '',
                index: index + 1,
                quantity: 1,
                product_discount: '', // Product_discount (in percentage)
                product_price: '', // Product_price. The price of the product.Discount offered for a particular product.
                product_discounted_price: '', // Product_discounted_price. The price of the product after applying the discount.
                product_tags: 'General', // product_tags.Tag attached to the product i.g Exclusive, New etc.
                product_department: '' // product_department.Department the product belongs to i.g Makeup, Perfume, Care etc.
            }

            if (!product) return product_details

            if (
                product.category_hierarchy !== undefined &&
                product.category_hierarchy.length
            ) {
                product.category_hierarchy.map((val, index) => {
                    product_details[`item_category${index ? index + 1 : '' || ''}`] =
                        val.en_name
                })
                product_details.product_department =
                    product.category_hierarchy[0].en_name
            }

            if (product.option !== undefined && product.option.length) {
                product_details.item_variant = product.option[0].value
            }

            if (product.special !== undefined && product.special.length) {
                product_details.product_discount = product.special[0].discount_ratio.replace(
                    '-',
                    ''
                )
                product_details.product_discounted_price = product.special[0].price_formated.replace(
                    /[^0-9.-]+/g,
                    ''
                )
            }

            if (product.special !== undefined && product.is_exclusive) {
                product_details.product_tags = 'Exclusive'
            }

            if (product.special !== undefined && product.is_new) {
                product_details.product_tags = 'New'
            }

            if (product.manufacturer !== undefined && product.manufacturer) {
                product_details.item_brand = product.manufacturer
            }

            if (
                product.manufacturer_name !== undefined &&
                product.manufacturer_name
            ) {
                product_details.item_brand = product.manufacturer_name
            }

            if (
                passedQuantity &&
                product.quantity !== undefined &&
                product.quantity
            ) {
                product_details.quantity = product.quantity
            }

            if (product.id !== undefined && product.id) {
                product_details.item_id = product.id
            }

            if (product.product_id !== undefined && product.product_id) {
                product_details.item_id = product.product_id
            }

            product_details.item_name =
                product.en_name !== undefined ? product.en_name : product.name
            product_details.price =
                product.price_formated !== undefined
                    ? product.price_formated.replace(/[^0-9.-]+/g, '')
                    : Number(product.price).toFixed(2)
            product_details.product_price =
                product.price_formated !== undefined
                    ? product.price_formated.replace(/[^0-9.-]+/g, '')
                    : Number(product.price).toFixed(2)

            return product_details
        })
}

export const ecommerceItemGA3 = (
    product,
    {selectedOption = null, index = 0, passedQuantity = false} = 0
) => {
    const product_details = {
        name: '', // Product Name. Product Name or Product ID is required.
        id: '', // Product ID
        price: '', // Product Price
        brand: '', // Product Brand
        category: '', // Product Category/Product Subcategory1/Product Subcategory2
        quantity: 1,
        variant: '', // Product Color or product variant etc.
        position: index + 1, // Product Position in the list
        dimension9: '', // Product_discount (in percentage)
        dimension10: '', // Product_price. The price of the product.Discount offered for a particular product.
        dimension11: '', // Product_discounted_price. The price of the product after applying the discount.
        dimension12: 'General', // product_tags.Tag attached to the product i.g Exclusive, New etc.
        dimension13: '' // product_department.Department the product belongs to i.g Makeup, Perfume, Care etc.
    }

    if (!product) return product_details
    if (
        product.category_hierarchy !== undefined &&
        product.category_hierarchy.length
    ) {
        const categories = product.category_hierarchy.map(
            category => category.en_name
        )
        product_details.category = categories.join('/')
        product_details.dimension13 = product.category_hierarchy[0].en_name
    }

    if (selectedOption) {
        product_details.variant = selectedOption.en_name
            ? selectedOption.en_name
            : selectedOption.name
    }

    if (product.option !== undefined && product.option.length) {
        product_details.variant = product.option[0].value
    }

    if (product.special !== undefined && product.special.length) {
        product_details.dimension9 = product.special[0].discount_ratio.replace(
            '-%',
            ''
        )
        product_details.dimension11 = product.special[0].price_formated.replace(
            /[^0-9.-]+/g,
            ''
        )
    }

    if (product.special !== undefined && product.is_exclusive) {
        product_details.dimension12 = 'Exclusive'
    }

    if (product.special !== undefined && product.is_new) {
        product_details.dimension12 = 'New'
    }

    if (product.manufacturer !== undefined && product.manufacturer) {
        product_details.brand = product.manufacturer
    }

    if (product.manufacturer_name !== undefined && product.manufacturer_name) {
        product_details.brand = product.manufacturer_name
    }

    if (passedQuantity && product.quantity !== undefined && product.quantity) {
        product_details.quantity = product.quantity
    }

    if (product.id !== undefined && product.id) {
        product_details.id = product.id
    }

    if (product.product_id !== undefined && product.product_id) {
        product_details.id = product.product_id
    }

    product_details.name =
        product.en_name !== undefined ? product.en_name : product.name
    product_details.dimension10 =
        product.price_formated !== undefined
            ? product.price_formated.replace(/[^0-9.-]+/g, '')
            : Number(product.price).toFixed(2)
    product_details.price =
        product.price_formated !== undefined
            ? product.price_formated.replace(/[^0-9.-]+/g, '')
            : Number(product.price).toFixed(2)

    return product_details
}

export const ecommerceMultipleItemsGA3 = (
    products,
    {passedQuantity = false} = 0
) => {
    let product_details = {}

    if (products && !products.length) return product_details
    if (products)
        return products.map((product, index) => {
            product_details = {
                name: '', // Product Name. Product Name or Product ID is required.
                id: '', // Product ID
                price: '', // Product Price
                brand: '', // Product Brand
                category: '', // Product Category/Product Subcategory1/Product Subcategory2
                quantity: 1,
                variant: '', // Product Color or product variant etc.
                position: index + 1, // Product Position in the list
                dimension9: '', // Product_discount (in percentage)
                dimension10: '', // Product_price. The price of the product.Discount offered for a particular product.
                dimension11: '', // Product_discounted_price. The price of the product after applying the discount.
                dimension12: 'General', // product_tags.Tag attached to the product i.g Exclusive, New etc.
                dimension13: '' // product_department.Department the product belongs to i.g Makeup, Perfume, Care etc.
            }

            if (!product) return product_details

            if (
                product.category_hierarchy !== undefined &&
                product.category_hierarchy.length
            ) {
                const categories = product.category_hierarchy.map(
                    category => category.en_name
                )
                product_details.category = categories.join('/')
                product_details.dimension13 = product.category_hierarchy[0].en_name
            }

            if (product.option !== undefined && product.option.length) {
                product_details.variant = product.option[0].value
            }

            if (product.special !== undefined && product.special.length) {
                product_details.dimension9 = product.special[0].discount_ratio.replace(
                    '-%',
                    ''
                )
                product_details.dimension11 = product.special[0].price_formated.replace(
                    /[^0-9.-]+/g,
                    ''
                )
            }

            if (product.special !== undefined && product.is_exclusive) {
                product_details.dimension12 = 'Exclusive'
            }

            if (product.special !== undefined && product.is_new) {
                product_details.dimension12 = 'New'
            }

            if (product.manufacturer !== undefined && product.manufacturer) {
                product_details.brand = product.manufacturer
            }

            if (
                product.manufacturer_name !== undefined &&
                product.manufacturer_name
            ) {
                product_details.brand = product.manufacturer_name
            }

            if (
                passedQuantity &&
                product.quantity !== undefined &&
                product.quantity
            ) {
                product_details.quantity = product.quantity
            }

            if (product.id !== undefined && product.id) {
                product_details.id = product.id
            }

            if (product.product_id !== undefined && product.product_id) {
                product_details.id = product.product_id
            }

            product_details.name =
                product.en_name !== undefined ? product.en_name : product.name
            product_details.dimension10 =
                product.price_formated !== undefined
                    ? product.price_formated.replace(/[^0-9.-]+/g, '')
                    : Number(product.price).toFixed(2)
            product_details.price =
                product.price_formated !== undefined
                    ? product.price_formated.replace(/[^0-9.-]+/g, '')
                    : Number(product.price).toFixed(2)

            return product_details
        })
}

export const ecommerceBannerGA3 = banner => {
    const bannerDetails = {
        id: '', // i.e. RB#1  // Name or ID is required.
        name: '', // i.e. North & West
        creative: '', // i.e. Summer Freecharge
        position: '' // i.e. banner1F
    }

    if (!banner) return bannerDetails

    if (banner.banner_id !== undefined && banner.banner_id) {
        bannerDetails.id = banner.banner_id
    }

    if (banner.name !== undefined && banner.name) {
        bannerDetails.name = banner.name
        bannerDetails.creative = banner.name
    }

    return bannerDetails
}

export const ecommerceBannerGA4 = banner => {
    const bannerDetails = {
        creative_name: '',
        creative_slot: '',
        location_id: '',
        promotion_id: '',
        promotion_name: ''
    }

    if (!banner) return bannerDetails

    if (banner.banner_id !== undefined && banner.banner_id) {
        bannerDetails.location_id = banner.banner_id
        bannerDetails.promotion_id = banner.banner_id
    }

    if (banner.name !== undefined && banner.name) {
        bannerDetails.creative_name = banner.name
        bannerDetails.promotion_name = banner.name
    }

    return bannerDetails
}

export const ecommerceCompleteOrderGA3 = order => {
    const orderDetails = {
        id: '', // Transaction ID. Required for purchases and refunds.
        affiliation: 'Niceone',
        revenue: '', // Total transaction value (incl. tax and shipping)
        tax: '',
        shipping: '',
        coupon: ''
    }

    if (!order) return orderDetails

    if (order.order_id !== undefined && order.order_id) {
        orderDetails.id = order.order_id
    }
    if (order.total !== undefined && order.total) {
        orderDetails.revenue = Number(order.total).toFixed(2)
    }
    if (order.coupon !== undefined && order.coupon) {
        orderDetails.coupon = order.coupon
    }
    if (order.totals !== undefined && order.totals.length) {
        orderDetails.shipping = Number(
            order.totals.filter(val => val.code === 'shipping')[0].value
        ).toFixed(2)
    }

    return orderDetails
}

export const ecommerceCompleteOrderGA4 = order => {
    const orderDetails = {
        transaction_id: '',
        affiliation: 'Niceone',
        value: '',
        tax: '',
        shipping: '',
        currency: 'SAR',
        coupon: ''
    }

    if (!order) return orderDetails

    if (order.order_id !== undefined && order.order_id) {
        orderDetails.transaction_id = order.order_id
    }
    if (order.total !== undefined && order.total) {
        orderDetails.value = Number(order.total).toFixed(2)
    }
    if (order.coupon !== undefined && order.coupon) {
        orderDetails.coupon = order.coupon
    }
    if (order.totals !== undefined && order.totals.length) {
        orderDetails.shipping = Number(
            order.totals.filter(val => val.code === 'shipping')[0].value
        ).toFixed(2)
    }

    return orderDetails
}


export const gtmPageName = () => {
    const route = useRoute()
    const routeName = route.name.split('__')[0]
    switch (routeName) {
        case 'index':
            return 'Home Page'
        case 'slugs':
            return 'Product List Page'
        case 'product-details':
            return 'Product Detail page'
        case 'checkout-address-list':
        case 'checkout-address-id':
            return 'Checkout Address Page'
        case 'addresses':
            return 'Address Page'
        default:
            return `${routeName.replaceAll('-', ' ')} Page`
    }
}

export const gtmCommonObjects = vm => {
    const route = useRoute()

    const authStore = useAuthStore()
    const {user, isLoggedIn} = storeToRefs(authStore)

    return {
        page_type: gtmPageName(),
        user_phone: isLoggedIn.value ? `+${user.value?.telephone}` : '',
        user_email: isLoggedIn.value ? user.value?.email : '',
        client_id: isLoggedIn.value ? user.value?.customer_id : '',
        user_type: isLoggedIn.value ? 'Logged-In' : 'Guest',
        country: getEnCountryName(),
        page_url: `https://niceonesa.com${route.path}`
    }
}

export const gtmProductDetails = product => {
    let product_details = ''

    if (!product) return product_details

    if (product.special !== undefined && product.special.length) {
        product_details += `disc = ${product.special[0].discount_ratio.replace(
            '-',
            ''
        )} | `
        product_details += `disc_price = ${product.special[0].price_formated.replace(
            /[^0-9.-]+/g,
            ''
        )} | `
    }

    if (product.special !== undefined && product.is_exclusive) {
        product_details += `tags = Exclusive | `
    }

    if (product.special !== undefined && product.is_new) {
        product_details += `tags = New | `
    }

    if (product.manufacturer !== undefined && product.manufacturer) {
        product_details += `brand = ${product.manufacturer} | `
    }

    product_details += `name = ${
        product.en_name !== undefined ? product.en_name : product.name
    } | `
    product_details += `price = ${
        product.price_formated !== undefined
            ? product.price_formated.replace(/[^0-9.-]+/g, '')
            : product.price
    }`
    return product_details
}
export const gtmCategoriesHamburgerMenu = categories => {
    return categories
        .toString()
        .substring(1, categories.length - 1)
        .replaceAll('/', ' > ')
}
export const gtmCartArrayPipeSeperated = products => {
    return products
        .map(item => item.name)
        .toString()
        .replaceAll(',', ' | ')
}

export const gtmReviewSortLabel = value => {
    value = value.toLowerCase()
    switch (value) {
        case 'desc':
            return 'Recently added first'
        case 'desc_rating':
            return 'Highest rating first'
        default:
            return ''
    }
}
export const gtmPaymentType = type => {
    type = type.toLowerCase()
    switch (type) {
        case [TAMARA_PAYMENT_TYPES.PAY_BY_INSTALMENTS]:
            return 'Tamara Buy Now - Pay in Instalments'
        case [TAMARA_PAYMENT_TYPES.PAY_BY_LATER]:
            return 'Tamara Buy Now - Pay Later'
        case [PAYMENT_TYPES.CHECKOUT_PAYMENT]:
            return 'Credit / Master Card'
        case [PAYMENT_TYPES.APPLE_PAY]:
            return 'Apple Pay'
        case [PAYMENT_TYPES.COD]:
            return 'Cash On Delivery'
        default:
            return `Payment`
    }
}